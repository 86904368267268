<script>

/* COMPOSANTS */
import AkFormList from "@components/layout/AkFormList";
import AkDialog from "@components/general/AkDialog";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanFormationService from "@services/bilanFormationService";

export default {
  components: {AkFormList, AkDialog, AkDropdown},
  mixins: [randomRef, utilsMixin, roleMixin],
  metaInfo() {
    return {
      title: "bilan_formation.list",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      list: [],
      currentYear: null,
      current: {},
      loading: true,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();
  
    let p1 = bilanFormationService.findAllCurrent();
    p1.then(data => this.list = data);
  
    let p2 = bilanFormationService.findCurrentYear();
    p2.then(data => this.currentYear = data);
  
    let p3 = bilanFormationService.findAllYears();
    p3.then(data => {
      this.yearList.push({value: 0, label: this.$t('current_year')});
      data.forEach(year => this.yearList.push({value: year, label: year + ' - ' + (year + 1)}))
    });
  
    Promise.all([p1, p2, p3]).then(()=>{
      this.loading = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1;
      if (year !== 0) {
        p1 = bilanFormationService.findAllByYear(year);
        p1.then(data => this.list = data);
      } else {
        p1 = bilanFormationService.findAllCurrent();
        p1.then(data => this.list = data);
      }
    
      Promise.all([p1]).then(()=>{
        this.loading = false;
        this.getRef().hideLoader();
      });
    },
    delete() {
      this.getRef().resetMessages();
      bilanFormationService.delete(this.current).then(data => {
        this.list = this.list.filter(val => val.id !== data.id);
        this.getRef().success(this.$t("bilan.deleted"));
      }).catch(e => {
        this.getRef().error(e.response.data.error);
      });
    },

    openDeleteDialog(data) {
      this.current = data;
      this.$refs.dialogDelete.show();
    },
  },
  watch: {
    year(newType) {
      this.reload(newType);
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormList :ref="ref" :title="$t('bilan_formation.list')" :displayGoBack=true>
    <template v-slot:action>
      <router-link :to="{ name: 'bilanFormationSynthese' }" class="btn btn-inverse-primary mr-2">
        {{ $t('goto_table') }}
      </router-link>
      <RouterLink v-if=this.canCreateFormation() :to="{ name: 'bilanFormationCreate' }" class="btn btn-inverse-primary">
        <i class="fe fe fe-plus pr-1"/>{{ $t('add') }}
      </RouterLink>
    </template>
    <template v-slot:list>
      <div class="row">
        <AkDropdown :label="$t('year')"
                    v-if=this.canViewFormationArchived()
                    v-model="year"
                    :options=this.yearList
                    class-name="col-md-2"/>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive">
                <DataTable :always-show-paginator="false" :paginator="true" :rows="10" :loading="loading"
                           :rowsPerPageOptions="[10,20,50]" :value="list" class="table"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           removableSort responsiveLayout="scroll" stripedRows>
                  <template #empty>
                    {{ $t("list_empty") }}
                  </template>
                  <Column :header="$t('year')">
                    <template #body="slotProps">
                      <template v-if=!slotProps.data.year>{{ currentYear }} - {{ currentYear + 1 }}</template>
                      <template v-else>{{ slotProps.data.year }} - {{ slotProps.data.year + 1 }}</template>
                    </template>
                  </Column>
                  <Column field="agerLabel" :header="$t('ager_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.agerLabel }}
                    </template>
                  </Column>
                  <Column field="uerLabel" :header="$t('uer_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.uerLabel }}
                    </template>
                  </Column>
                  <Column field="ceiLabel" :header="$t('cei_label')" :sortable="true">
                    <template #body="slotProps">
                      {{ slotProps.data.ceiLabel }}
                    </template>
                  </Column>
                  <Column bodyStyle="text-align: right; overflow: visible">
                    <template #body="slotProps">
                      <RouterLink :to="{name: 'bilanFormationDetails', params: {id: slotProps.data.id}}"
                                   class="btn btn-xs btn-inverse-primary">
                        <i class="fe fe-eye"/>
                      </RouterLink>
                      <RouterLink v-if="!slotProps.data.year && this.canEditFormation() && this.canEditOrDeleteReview(slotProps.data)"
                                  :to="{name: 'bilanFormationUpdate', params: {id: slotProps.data.id}}"
                                  class="btn btn-xs btn-inverse-primary ml-1">
                        <i class="fe fe-edit"/>
                      </RouterLink>
                      <span v-if="this.canDeleteFormation() && this.canEditOrDeleteReview(slotProps.data)"
                            @click="openDeleteDialog(slotProps.data)"
                            class="btn btn-xs btn-inverse-danger ml-1">
                        <i class="fe fe-trash"/>
                      </span>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:extra>
      <AkDialog :auto-hide-on-validate="true" :cancel-label="$t('no')" :title="$t('bilan.delete_dialog')"
                :validate-label="$t('yes')" @validate="this.delete()" ref="dialogDelete" width="450px">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('bilan.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormList>
</template>